.gallery-header {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 50%;
}



.search-icon {
  position: absolute;
  left: 10px;
  font-size: 20px;
}

.search-input {
  padding: 10px 20px 10px 40px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.gallery-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
  cursor: pointer;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.gallery-item:hover {
  transform: translateY(-10px);
}

.gallery-item img {
  width: 100%;
  height: 300px; 
  object-fit: contain; 
}
.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.image-overlay h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.image-overlay p {
  font-size: 14px;
  margin-top: 5px;
}

.load-more {
  margin-top: 20px;
}

.no-images {
  text-align: center;
}

.no-images img {
  width: 50%;
  margin-bottom: 20px;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  position: relative;
  max-width: 50%;
  max-height: 80%;
  overflow: hidden;
  border-radius: 10px;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px !important; 
  padding: 30px;
}

.modal-info {
  margin-top: 10px;
  text-align: center;
}

.modal-info h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.modal-info p {
  font-size: 16px;
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  background: none;
  border: none;
}

.modal-close-icon:hover {
  color: rgb(187, 179, 179);
}

@media (max-width: 425px) {
  input[type="text"] {
    width: 100%;
  }
  .gallery-header {
    justify-content: center;
  }
  .search-input-container {
    width: 100%;
}
.modal-content {
  background: white;
  padding: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow: hidden;
  border-radius: 10px;
}
}
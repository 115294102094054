

.enquiry-page {
  /* background-color: honeydew; */
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.enquiry-container {
  background-color: rgba(176, 164, 164, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.product-details {
  flex: 1;
  padding-right: 30px;
  color: #fff;
}

.product-details h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.product-details p {
  font-size: 16px;
  line-height: 1.5;
}

.product-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.form-section {
  flex: 1;
  background-color: rgba(49, 48, 48, 0.7); 
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.form-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.form-section p {
  font-size: 18px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  /* background-color: #f4f4f4; */
}

.form-group textarea {
  height: 150px;
}

button {
  background-color: #6a4e23; 
  color: white;
  border: none;
  padding: 15px 25px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

button:hover {
  background-color: #7a5a34;
}


@media (max-width: 768px) {
  .enquiry-container {
    flex-direction: column;
    padding: 15px;
  }

  .product-details,
  .form-section {
    padding: 20px;
  }

  .form-section h1 {
    font-size: 28px;
  }

  .product-details h2 {
    font-size: 20px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
  }

  button {
    font-size: 16px;
  }
}

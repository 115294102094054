*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.karungali img{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: 75%;
}
.header{
    font-weight: 900;
}

@media screen and (max-width:767px) {
    img{width: 100%;}
    .header{
        font-weight:600;
        padding: 0px 40px;
    }
}
.hero-carousel-container {
  position: relative;
  overflow: hidden;
  height: 80vh;
   padding: 65px 0px 0px 0px;
}

/* .custom-carousel {
  width: 100%;
  height: 70vh;
} */

.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-img {
  position: relative; 
  /* background: #f8d7da; */
  background-image: url('../../assests/wood-image/home/offer/image-1.png');
  background-size: contain; /* Cover the entire banner */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating */
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  min-height: 500px;
}

.banner-img::before {
  content: ''; 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  /* background-color: rgb(16, 15, 15); */
 /* opacity: 0.6;  */

}

.banner-img > * {
  position: relative; /* Ensure text and buttons appear above the pseudo-element */
  z-index: 2; /* Higher z-index to stay on top */
}



.banner h1 {
  font-size: 2.5rem;
  color: #721c24;
}

.banner p {
  font-size: 1.25rem;
  color: #721c24;
}

/* .banner-title {
  font-size: 4rem;
  margin: 0;
}

.banner-description {
  font-size: 1.5rem;
  margin: 0;
}

@media (max-width: 1200px) {
  .banner-title {
      font-size: 2.5rem;
  }
  .banner-description {
      font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .banner-title {
      font-size: 2rem;
  }
  .banner-description {
      font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .banner-title {
      font-size: 2.5rem;
  }
  .banner-description {
      font-size: 1.2rem;
  }
} */
.banner-title {
  font-size: clamp(1.5rem, 3vw, 2rem);
}

.banner-description {
  font-size: clamp(0.8rem, 2.5vw, 1.5rem);
}


.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pagination .page {
  border: #3498db !important;
}
/* =-------------------------------------------> */
.card {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 34px;
  color: #333;
  margin-bottom: 10px;
}

.card p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

.home-page {
  text-align: center;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .home-page {
    padding: 0px;
  }
  .hero-carousel-container{
    height: 40vh;
  }
  .banner-img {
    min-height: 200px;
  }
}
.comingSoon img{
  width: 100%;
}
.shop-now-btn {
  background-color: #373d43;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  margin-top: 20px;
}

.shop-now-btn:hover {
  background-color: #494d52;
}
.home-carousel .carousel-item {
  /* height: 500px;  */
}

.rounded-image {
  object-fit: contain;
  /* height: 100%; */
  border-radius: 15px; /* Adjust border radius as needed */
}

.card {
  border-radius: 15px; /* Add border radius to the card */
  overflow: hidden; /* Ensure the corners are clipped */
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next, .carousel-control-prev {
    display: none !important;
}

.carousel-indicators{
  display: none
}
/* 
:root {
  --primary-color: #3B8C8C;
  --secondary-color: #E1F5FE; 
  --accent-color: #E3F9E5;
  --highlight-color: #FFF3E2; 
  --text-dark: #2F2F2F;
  --text-light: #555;
  --text-medium: #777;
  --heading-font-size: 2.25rem; 
  --subheading-font-size: 1.5rem; 
  --card-padding: 1.25rem; 
  --transition-speed: 0.3s;
  --border-radius: 8px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}





.hero-section {
  background: linear-gradient(to bottom, #1a3a3a, #5c5c5c);
  padding: 5rem 0;
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.hero-heading {
  font-size: 3rem; 
  font-weight: bold;
  color: var(--secondary-color);
}

.hero-subheading {
  font-size: var(--subheading-font-size);
  margin: 1.25rem 0;
  color: var(--secondary-color);
}


.section-heading {
  font-size: var(--heading-font-size);
  color: var(--primary-color);
  margin: 1rem 0;
}


.mission-statement p {
  font-size: 1.125rem; 
  color: var(--text-dark);
  line-height: 1.6;
}

.values-section .feature-box {
  padding: var(--card-padding);
  border-radius: var(--border-radius);
  margin-bottom: var(--card-padding);
  text-align: center;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.values-section .feature-box:hover {
  transform: translateY(-0.625rem); 
  box-shadow: var(--box-shadow);
}


.values-section .feature-box-1 {
  background-color: var(--secondary-color); 
}

.values-section .feature-box-2 {
  background-color: var(--accent-color); 
}

.values-section .feature-box-3 {
  background-color: #fff2cc; 
}

.values-section .feature-box-4 {
  background-color: #f8d7da; 
}

.values-section .feature-box-5 {
  background-color: #f2d6f8; 
}

.values-section .feature-box-6 {
  background-color: #d6f8f5; 
}

.values-section .feature-box h3 {
  font-size: 1.375rem; 
  color: var(--text-dark);
  margin-bottom: 0.9375rem; 
  font-weight: bold;
}


.portfolio-section {
  background-color: #f8f9fa;
  padding: 3.75rem 0;
  text-align: center;
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.portfolio-section p {
  margin: 1.25rem 0;
  padding: 0 6.25rem; 
}


.contact-section {
  background-color: #52afaf;
  color: white;
  padding: 3.75rem 0;
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.contact-section h2 {
  font-size: 1.875rem; 
}

.contact-section p {
  font-size: 1.125rem;
  margin: 1.25rem 0;
  padding: 0 6.25rem; 
}


.vision-mission {
  background-color: var(--secondary-color);
  padding: 5rem 0;
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.vision-mission p {
  font-size: 1rem; 
  line-height: 1.6;
  color: var(--text-dark);
}

.vision-mission .section-heading {
  font-size: var(--heading-font-size);
  color: var(--primary-color);
  margin-bottom: 2rem;
 
}

.vision-mission .card {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.vision-mission .card:hover {
  transform: translateY(-0.625rem);
  box-shadow: var(--box-shadow);
}

.vision-mission .card-title {
  font-size: 1.5rem; 
  font-weight: bold;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 1.25rem;
}

.vision-mission .card-text {
  font-size: 1.125rem; 
  color: var(--text-dark);
  line-height: 1.6;
}


@media (max-width: 768px) {
  html {
    font-size: 90%; 
  }

  .hero-heading {
    font-size: 2.25rem; 
  }

  .hero-subheading {
    font-size: 1.25rem; 
  }

  .section-heading {
    font-size: 1.75rem; 
  }

  .feature-box h3 {
    font-size: 1.25rem; 
  }

  .mission-statement p,
  .portfolio-section p,
  .contact-section p {
    font-size: 1rem;
  }

  .contact-section p {
    padding: 0 1rem;
  }

  .portfolio-section p {
    padding: 0 1rem;
  }

  .vision-mission .card-text {
    font-size: 1rem; 
  }

  .vision-mission .card-title {
    font-size: 1.25rem; 
  }
}

@media (max-width: 767px) {
  .section-heading {
    font-size: 1.75rem;
  }

  .vision-mission .lead {
    font-size: 1rem; 
    padding: 0 1.5rem;
  }

  .portfolio-section p,
  .contact-section p {
    margin: 0;
    padding: 0;
  }
} */


.card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 2rem;
  font-weight: bold;
  color: #3B8C8C;
}

h4, h5 {
  color: #3B8C8C;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.accordion-button {
  color: #004085;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button:hover {
  background-color: #d2dbdd;
  color: #004085;
}
/* .navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1000;
    color: rgb(253, 253, 253);
    background-color: rgb(16, 15, 15);
}

.navbar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assests/images/bg-footer.png');
    background-size: cover;
    background-position: center;
    opacity: 0.2;

}


.nav-head {
    padding: 10px;
    margin: 0px 35px 0px 35px;
}

.navbar-headers {
    margin: 0px 35px 0px 35px;
}

.navbar img {
    width: 50px;
}

.toggle-button {
    display: none;
    cursor: pointer;
    color: white;
}

.navbar ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navbar li {
    margin: 0 15px;
    position: relative;
}

.navbar a {
    color: white;
    text-decoration: none;
}

.navbar a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .toggle-button {
        display: block;
        font-size: 30px;
    }

    .nav-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
    }

    .navbar ul {
        position: fixed;
        top: 0;
        left: -250px;
        width: 250px;
        height: 100%;
        background-color: #444;
        list-style-type: none;
        padding: 20px;
        margin: 0;
        transition: left 0.3s ease;
        flex-direction: column;
    }

    .navbar.active ul {
        left: 0;
        display: flex;
    }

    .navbar li {
        margin: 15px 0;
    }

    .close-button {
        cursor: pointer;
        color: white;
        font-size: 24px;
        display: flex;
        justify-content: end;
    }
}

@media (min-width: 769px) {
    .close-button {
        display: none;
    }
}

.dropdown-toggle {
    cursor: pointer;
    color: white;
}

.dropdown-menu {
    position: abslute;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    display: none;
    flex-direction: column;
    min-width: 150px;
    border-radius: 4px;
    width: 100%;
}

.dropdown-menu.show {
    display: flex;
}

.dropdown-menu li {
    margin: 0px 5px;
}

.dropdown-menu a {
    text-decoration: none;
    color: black;
    padding: 5px 7px;
    border-radius: 5px;
    display: block;
    width: 100%;
}

.options:hover {
    background-color: #005eb8;
    border-radius: 5px;
    box-shadow: 0 0 2px 0px;
    color: white;
}

.options:hover a {
    color: white;
    text-decoration: none;
}

.navbar li:hover .dropdown-menu {
    display: flex;

}

@media (max-width: 768px) {
    .dropdown-menu {
        position: static;
        display: block;
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
    }

    .dropdown-menu li {
        margin: 10px 0;
    }
} */

/* Navbar styles */
.navbar {
    position: relative;  /* Ensure that z-index works properly */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    z-index: 1000;  /* Make sure navbar stays on top of other content */
    color: rgb(253, 253, 253);
    /* background-color: rgba(0, 0, 0, 0.5);  Fallback background color */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: fixed;
    width: 100%;
    background-color: #6C3B1C;
  }
  
  /* Add a background image with opacity */
  .navbar::before {
    content: '';  
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://media.istockphoto.com/id/501534236/photo/red-wood-decorative-furniture-surface-xylia-xylocarpa-taub.jpg?s=612x612&w=0&k=20&c=D8O1jTbNqY5dFpAzcoaJbel8ewKECem4nc8xIvweyfI='); 
    background-size: cover;  /* Ensure the image covers the whole navbar */
    background-position: center;  /* Position image in the center */
    opacity: 0.8; 
    z-index: -1;
    background-color: #6C3B1C;  
  }
.dropdown-item:hover {
    border-radius: 5px;
    /* margin: 0px 5px 0px 5px; */
    box-shadow: 0 0 2px 0px;
    /* color: gold !important; */
    background-color: #ebeaea !important;
    border: 0px !important; 
}

.dropdown-item {
    /* color: #363636 !important; */
}

.dropdown-menu {
    background-color: #f8f9fa !important;
    border-radius: 4px;
    z-index: 1050 !important;
    padding:10px;
}

.navbar {
    background-color: #333 !important;
}


.navbar .nav-link {
    color: #f7f1f1 !important;
    margin-right: 20px !important;
}


.navbar .nav-link:hover {
    color: gold !important;
    text-decoration: none;
}

.navbar .dropdown:hover .dropdown-menu {
    display: block !important;
}
.items-mobile-view{
    text-align: center;
    /* color: gold !important; */
    font-weight: 700;
    font-size: 17px;
    text-decoration: none;
}
.drop-list{
    display: inline-block;
}

.navbar img{
    width: 320px;
}
@media screen and (max-width: 768px) {
    .navbar img{
        width: 290px;
    }
    .navbar .container{padding-left:0px ;padding-right: 20px;}
}
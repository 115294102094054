#contact {
  .ftco-section {
    padding: 2em 0 7em 0;
    position: relative;
  }
   
  .bg-light {
    background: #f5f4f0 !important;
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
   
  .dbox {
    width: 100%;
    margin-bottom: 25px;
  }
   
  .dbox .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* background: #61433f; */
    background-image: url('https://media.istockphoto.com/id/501534236/photo/red-wood-decorative-furniture-surface-xylia-xylocarpa-taub.jpg?s=612x612&w=0&k=20&c=D8O1jTbNqY5dFpAzcoaJbel8ewKECem4nc8xIvweyfI='); 
    margin: 0 auto;
    margin-bottom: 20px;
  }
   
  .dbox .icon span {
    font-size: 30px;
    color: #fff;
  }
   
  .dbox p {
    margin-bottom: 0;
  }
   
  .dbox p span {
    font-weight: 500;
    color: #000000;
  }
   
  .contact-wrap {
    background: #fff;
  }
   
  .form-group {
    margin-bottom: 1rem;
  }
   
  .contactForm .label {
    color: #61433f !important;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
  }
   
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
   
  .contactForm .form-control {
    height: 36px;
    background: #fff;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
   
  .gm-err-container {
    height: 100%;
    width: 100%;
    display: table;
    background-color: #e8eaed;
    position: relative;
    left: 0;
    top: 0;
  }
  .icon-colorful {
    color: #007bff; /* Change this to your desired color */
    font-size: 2rem; /* Adjust size as needed */
    transition: color 0.3s ease; /* Smooth color transition on hover */
}

.icon-colorful:hover {
    color: #0056b3; /* Darker shade on hover */
}

.text a {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease; /* Smooth transition */
}

.text a:hover {
    color: #0056b3; /* Darker shade on hover */
}

@media (max-width: 768px) {
    .dbox {
        margin-bottom: 20px; /* Spacing between boxes on mobile */
    }
}

}


.footer {
  /* background-color: rgb(16, 15, 15); */
  padding: 20px;
  color: rgb(253, 253, 253);
  opacity: 1;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  /* background: rgb(0 0 0 / 43%); */
  /* Black overlay with transparency */
  color: #ffffff;
  /* White text */
}

.footer-menu li {
  font-size: 16px;
}

.footer-menu li:hover {
  text-decoration: underline;
}

.footer-main {
  /* background-color: rgb(16, 15, 15); */
  background-image: url('https://media.istockphoto.com/id/501534236/photo/red-wood-decorative-furniture-surface-xylia-xylocarpa-taub.jpg?s=612x612&w=0&k=20&c=D8O1jTbNqY5dFpAzcoaJbel8ewKECem4nc8xIvweyfI=');
  /* background-color: #6C3B1C; */
  background-size: cover;
  background-position: center;
  color: rgb(253, 253, 253);
  /* opacity: 0.9; */
}

.footer-address {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;

}

.footer-address p {
  font-size: 16px;
}

.social-media {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.social-media:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ff4757;
}


.social-icons a.facebook {
  color: #3b5998;
}

.social-icons a.twitter {
  color: #1da1f2;
}

.social-icons a.instagram {
  color: #c32aa3;
}

.social-icons a.linkedin {
  color: #0077b5;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.8em;
}

.map-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.map-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* .contact-container{
  display: flex;
  justify-content: end;
  width: 100%;
}
.contact-us {
  background: linear-gradient(90deg, #e94057, #f27121 103.31%);
  border: #ffdead;
  border-radius: 20px;
  color: #fff;
  display: flex;
  font-family: poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  padding: .5rem;
  text-align: center;
} */


@media (min-width: 600px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

}

@media (max-width: 600px) {
  .footer-address {
    justify-content: start;
    align-items: start;

  }
}

.address-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.address-item i {
  margin-right: 10px;
  color: #ff4757;
  font-size: 18px;
}

.footer-main h4 {
  color: gold;
  font-weight: 700;
}
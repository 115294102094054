
.accordion-button {
  color: #004085;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button:hover {
  background-color: #e3f0f5;
  color: #004085;
}
  
  .accordion-item {
    margin-bottom: 10px;
  }
  
  .card {
    border: none;
    border-radius: 10px;
  }
  
  .card-body {
    padding: 0rem;
  }
  
.product-list {
  padding: 20px;
  text-align: center;
}

.women-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  max-height: 500px;
}

:root {
  --primary-color: #3B8C8C;
  --secondary-color: #E1F5FE;
  --accent-color: #E3F9E5;
  --highlight-color: #FFF3E2;
  --text-dark: #2F2F2F;
  --text-light: #555;
  --text-medium: #777;
  --heading-font-size: 2.25rem;
  --subheading-font-size: 1.5rem;
  --card-padding: 1.25rem;
  --transition-speed: 0.3s;
  --border-radius: 8px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}


html {
  font-size: 100%;
}

.hero-section {
  position: relative;
  background-image: url('../../assests/all-images/about-us/image-1.png');
  /* background-size: cover; */
  background-position: center;
  background-attachment: fixed;
  padding: 5rem 0;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.hero-section::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero-section .content {
  position: relative;
  z-index: 2;
}

.hero-section button {
  position: relative;
  z-index: 3;
}


.hero-section .hero-subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}

.hero-heading {
  font-size: 3rem;
  font-weight: bold;
  color: var(--secondary-color);
  position: relative;
  z-index: 2;
}
.hero-subheading {
  font-size: var(--subheading-font-size);
  margin: 1.25rem 0;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}


.section-heading {
  font-size: var(--heading-font-size);
  /* color: var(--primary-color); */
  color: #9A6D78;
  margin: 1rem 0;
}


.mission-statement p {
  font-size: 1.125rem;
  color: var(--text-dark);
  line-height: 1.6;
}

.values-section .feature-box {
  padding: var(--card-padding);
  border-radius: var(--border-radius);
  margin-bottom: var(--card-padding);
  text-align: center;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 180px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.values-section .feature-box:hover {
  transform: translateY(-0.625rem);
  box-shadow: var(--box-shadow);
}

.values-section .feature-box-1 {
  background-image: url('../../assests/aboutUs-images/image-1.png');
  color: #ffffff;
}

.values-section .feature-box-2 {
  background-image: url('../../assests/aboutUs-images/image-2.jpg');
  color: #624d26;
}

.values-section .feature-box-3 {
  background-image: url('../../assests/aboutUs-images/image-3.png');
  color: #fff5e2;
}

.values-section .feature-box-4 {
  background-image: url('../../assests/aboutUs-images/image-8.png');
  color: #373434;
}

.values-section .feature-box-5 {
  background-image: url('../../assests/aboutUs-images/image-7.jpg');
  color: #fff8e7;
}

.values-section .feature-box-6 {
  background-image: url('../../assests/aboutUs-images/image-9.png');
  color: #eee4da;
}

.values-section .feature-box h3 {
  font-size: 1.375rem;
  /* color: var(--text-dark); */
  margin-bottom: 0.9375rem;
  font-weight: 800;
}


.portfolio-section {
  background-color: #f8f9fa;
  padding: 3.75rem 0;
  text-align: center;
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.portfolio-section p {
  margin: 1.25rem 0;
  padding: 0 6.25rem;
}

.contact-section {
  background-color: #52afaf;
  color: white;
  padding: 3.75rem 0;
  border-radius: var(--border-radius);
  margin: 1rem 0;
  position: relative;
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.text-overlay {
  position: relative;
  z-index: 2;
}

.contact-section h2 {
  font-size: 1.875rem;
  font-weight: bold;
}

.contact-section p {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 1.25rem 0;
  padding: 0 6.25rem;
}


.vision-mission {
  background-color: var(--secondary-color);
  background-image: url('../../assests/all-images/about-us/image-2.png');
  /* background-size: cover; */
  background-position: center;
  background-attachment: fixed;
  padding: 5rem 0;
  border-radius: var(--border-radius);
  margin: 1rem 0;
  color: #fff;
}

.vision-mission p {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.6;
  color: var(--text-dark);
}

.vision-mission .section-heading {
  font-size: var(--heading-font-size);
  color: var(--secondary-color);
  margin-bottom: 2rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1.5s ease-out;
}

.vision-mission .card {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.vision-mission .card:hover {
  transform: translateY(-0.625rem);
  box-shadow: var(--box-shadow);
}

.vision-mission .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 1.25rem;
  animation: slideIn 0.5s ease-in-out;
}

.vision-mission .card-text {
  font-size: 1.125rem;
  color: var(--text-dark);
  line-height: 1.6;
}

.vision-mission .highlight-text {
  color: #ffcc00;
  font-weight: bold;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  animation: pulseText 2s infinite ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.vision-mission .lead {
  font-size: 1.25rem;
  font-weight: normal;
  color: #fff;
}




@media (max-width: 768px) {
  html {
    font-size: 90%;
  }

  .hero-heading {
    font-size: 2.25rem;
  }

  .hero-subheading {
    font-size: 1.25rem;
  }

  .section-heading {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .feature-box h3 {
    font-size: 1.25rem;
  }

  .hero-section {
    margin: 2px !important;
  }

  .contact-section {
    margin: 2px !important;
  }

  .mission-statement p,
  .portfolio-section p,
  .contact-section p {
    font-size: 1rem;
  }

  .contact-section p {
    padding: 0 1rem;
  }

  .vision-mission {
    margin: 2px !important;
  }

  .portfolio-section p {
    padding: 0 1rem;
  }

  .vision-mission .card-text {
    font-size: 1rem;
  }

  .vision-mission .card-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 767px) {
  .section-heading {
    font-size: 1.75rem;
  }

  .vision-mission .lead {
    font-size: 1rem;
    padding: 0 1.5rem;
  }

  .portfolio-section p,
  .contact-section p {
    margin: 0;
    padding: 0;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* .projects-thumb {
  z-index: 1;
} */

#nav-business-tab h3 {
  font-size: 35px;
  font-weight: bolder;
}

.nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  border-left: 7px solid #717275 !important;
  margin-bottom: 30px;
  padding: 30px;
  transition: all 0.3s ease-in-out;
}

.projects-thumb-large {
  /* min-height: 356px; */
}

.projects-thumb {
  border-radius: 20px;
  /* position: relative; */
  overflow: hidden;
  margin-bottom: 24px;
  /* transition: all 0.3s; */
  cursor: pointer;
  height: 320px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

/* .projects-thumb:hover {
  background: #ffffff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
} */

.projects-image {
  border-radius: 20px;
  width: 100%;
  height: auto;
  transition: all 0.3s;
}

/* .projects-thumb-large .projects-image {
  object-fit: cover;
  height: 100%;
} */

/* .projects-thumb:hover .projects-image {
  transform: scale(1.2);
} */

.img-fluid {
  max-width: 100%;
  height: 100%;
}


.custom-btn {
  background: #dc3545;
  border-radius: 100px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  padding: 12px 24px;
  text-decoration: none;

}

.projects-btn-wrap {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.projects-title-wrap {
  background: linear-gradient(to top, #000000, transparent 90%);
  /* position: absolute; */
  bottom: 0;
  right: 0;
  left: 0;
  padding: 25px 25px 10px 25px;
  transition: all 0.2s;
}

.projects-btn-wrap .custom-btn {
  font-size: 30px;
  padding: 7.5px 15px;
}

.projects-thumb:hover .custom-btn,
.projects-btn-wrap .custom-btn:hover {
  background: #f9c10b;
  ;
}

.projects-small-title {
  color: #f9c10b;
  text-transform: uppercase;
}

.custom-btn:hover {
  background: #f9c10b;
  color: #ffffff;
}

.projects-title {
  color: #ffffff !important;
}

.projects-title h2 {
  font-size: 42px;
  letter-spacing: -2px;
}

.nav-item {
  width: 100%;
}

.nav-tabs .nav-link.active h3,
.nav-tabs .nav-link:focus h3,
.nav-tabs .nav-link:hover h3 {
  color: #f9c10b;
}

.nav-tabs .nav-link.active h3,
.nav-tabs .nav-link:focus h3,
.nav-tabs .nav-link h3 {
  font-weight: bold;
  letter-spacing: -1px;

}


.nav-tabs .nav-link,
.nav-tabs .nav-link span {
  color: #000000;
  display: block;
  text-align: left;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  width: 100%;
  border-left-color: #dc3545 !important;
  background: #ffffff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;

}

.about-thumb {
  /* background: #f9c10b; */
  border-radius: 20px;
  margin-bottom: 24px;
  padding: 20px;
}

.small-title {
  text-transform: uppercase;
}

.small-title h2 h3 {
  color: #000000;
}

.about-image {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image-wrap {
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.about-image-info {
  background: linear-gradient(to top, #000000, transparent 90%);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 40px;
}

@media screen and (max-width: 480px) {
  .projects-btn-wrap .custom-btn {
    font-size: 20px;
    padding: 5.5px 9px;
  }
}

/* --------------------------------/ */

.custom-bg-primary {
  background: #f9c10b;
}

.custom-bg-primary:hover {
  background: #ffffff;
  color: #000000;
}
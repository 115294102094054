.whatsapp-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    cursor: pointer;
}

.whatsapp-call-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}


@media (max-width: 600px) {
    .whatsapp-icon, .whatsapp-call-icon { 
        width: 25px; 
        height: 25px;
    }
}

.up-arrow-icon {
    position: fixed;
    bottom: 105px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    border-radius: 50%;
}

.left-arrow-icon{
    position: fixed;
    bottom: 30px;
    right: 25px;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}
.scrollToTopButton {
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    position: fixed;
    z-index: 999;
    width: 60px;
    margin-left: 0px;
    border-radius: 50%;
    animation: bounce 2s infinite;
}

.scrollToTopButton.visible {
    opacity: 1;
}
  @keyframes bounce {
   
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
   
    40% {
      transform: translateY(-20px);
    }
   
    60% {
      transform: translateY(-10px);
    }
  }
   
  @media screen and (max-width: 767px) {
    .floating-button {
      bottom: 100px !important;
    }
   
  }
   
  .floating-button img {
    width: 45px;
    height: 45px;
  }
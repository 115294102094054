.container {
    margin-top: 20px;
}

.product-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    transition: transform 0.2s;
}

.product-item:hover {
    transform: scale(1.05);
}

.product-images {
    width: 100%;
    height: 100%;
    padding: 10px;
}
.banner {
    background: linear-gradient(135deg, #f8d7da 0%, #f5c6cb 100%);
    padding: 30px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.banner h1 {
    font-size: 2.5rem;
    color: #721c24;
}

.banner p {
    font-size: 1.25rem;
    color: #721c24;
    margin: 10px 0;
}

.icons {
    margin: 20px 0;
}

.icon {
    font-size: 2rem;
    margin: 0 10px;
    color: #dc3545; /* Bootstrap's danger color */
    transition: transform 0.3s;
}

.icon:hover {
    transform: scale(1.2);
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
}

.product-detail-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}

.product-detail-image {
  position: relative;
  overflow: hidden;
  width: 300px; /* Set the desired size of the image container */
  height: 400px; /* Set the desired size of the image container */
}

.product-detail-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-detail-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.product-detail-image img:hover {
  transform: scale(1.2); /* Zoom effect */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.dress-shop {
  text-align: center;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  width: 200px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.image-container {
  position: relative;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .image-container {
    padding: 0px;
  }
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .overlay {
  opacity: 1;
}

.large-number {
  font-size: 2em;
  font-weight: bold;
}

.product-info {
  padding: 10px;
}

button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.hero-carousel-container {
  position: relative;
  overflow: hidden;
}

.custom-carousel {
  width: 100%;
  height: 50vh;
}

/* @media screen and (max-width: 767px) {
    .custom-carousel {
      width: 100%;
      height: 700px;
    }
  }   */
.custom-modal .modal-dialog {
  max-width: 800px;
  /* Adjust as needed */
}

.custom-modal .modal-body {
  height: 400px;
  /* Set your desired height */
  display: flex;
  /* Use flexbox to center content */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.custom-modal .modal-body img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 200px;
  /* Set your desired height */
}

.card-img-top {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-container:hover .card-img-top {
  transform: scale(1.05);
}